<template>
  <div class="popup_overlay">
  <div class="custom_dialog" :style="`width: 100%; overflow: auto; height:100vh;`">
    <div class="">
      <div >
        <div class="bg-primary flex justify-between p-2">
          <div class=" font-medium text-white heading-3 pl-1 overFlowParaA">
            {{object.displayFileName !== '' ? object.displayFileName : 'Attachment'}}
          </div>
          <div class="flex items-center">
            <div class=" text-primary heading-5 cursor-pointer bg-white px-4 py-1.5 rounded-xl ml-3" @click="redirectDetail(object)">
              Details
            </div>
            <div class=" text-error heading-3 cursor-pointer bg-white px-2.5 py-0.5 rounded-xl ml-3" @click="deleteAttch=true" v-if="!hideDelete">
              <i class="fas fa-trash"></i>
            </div>
            <div class=" text-primary heading-3 cursor-pointer bg-white px-2.5 py-0.5 rounded-xl ml-3" @click="discard(false)">
              <i class="fas fa-times"></i>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="flex items-center mb-4 image-show-container" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
          <div class=" font-medium text-text2 heading-4 pl-1 ">
            <div class="loader ease-linear ml-2"></div>
          </div>
        </div>
        <div v-if="loading">
          <div v-if="(object.docType === 'image' || object.docType === 'gif') && !isSHowErr" class="p-2">
            <img :style="`width: auto;max-height: 100%;height:` + imageDivHeight2 + `px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);`" class="" :src="srcUrl" alt="">
          </div>
          <div v-if="object.docType === 'pdf' && !isSHowErr" class="p-2" type="application/pdf">
            <iframe class="pdfIframe" :src="srcUrl" :style="`width: 100%;height:`+ imageDivHeight3 + `px;`" />
          </div>
          <div v-if="object.docType === 'video' && !isSHowErr" class="flex items-center mb-4">
            <video id="myVideo" autoplay class="mt-1" controls :style="`width: 100%;height:` + imageDivHeight2 + `px;`">
            <source  type="video/mp4" align="center" justify="center" class="ma-0" :src="srcUrl">
            </video>
          </div>
          <div v-if="(object.docType !== 'image' && object.docType !== 'gif' && object.docType !== 'pdf' && object.docType !== 'video') || isSHowErr" class="flex items-center mb-4 image-show-container" :style="`width: 100%;height:`+ imageDivHeight2 + `px;`">
            <div class=" font-medium text-text2 heading-4 pl-1 ">
                No Preview Available
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
  <ConfirmBox v-if="deleteAttch" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
</div>
</template>
<script>
import {downloadFile, checkFileTypeForPreview} from '@/utils/common.js'
import axios from 'axios'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import * as config from '@/config.js'
import {getCookies} from '@/utils/cookies'
export default {
components: {
  ConfirmBox
},
data () {
  return {
    isSHowErr: false,
    loading: false,
    deleteAttch: false,
    baseUrl: config.API_ROOT,
    srcUrl: '',
    imageDivHeight: window.innerHeight ,
    imageDivHeight3: window.innerHeight - 80,
    imageDivHeight2: window.innerHeight - 140
  }
},
props: ["object", "hideDelete"],
 created() {
},
mounted () {
  document.body.style = 'overflow: visible;'
  this.$root.$on('confirmBoxHandler', (response) => {
    if (response) {
      this.discard(true)
    }
    this.deleteAttch = false
  })
  this.downLoadDocument()
  console.log('srcUrl', this.srcUrl);
},
watch: {},
methods: {
  async downLoadDocument () {
      let Auth = getCookies('jobAuthToken')
      let url = this.baseUrl + '/api/v1/notes/getImageWeb'
      let formData = new FormData()
      formData.append('authToken', Auth)
      formData.append('fileVaultId', this.object.fileVaultId)
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      this.$store.dispatch('CancelDownloadRequest', source)
      let response = await downloadFile(formData, url, source)
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        if (checkFileTypeForPreview(response.headers['content-type'])) {
          this.srcUrl = URL.createObjectURL(response.data)
        }
        setTimeout(() => {
          this.loading = true
        }, 500);
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.isSHowErr = true
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    redirectDetail (data) {
      let value = data.entityDetailId
      let url = ''
      if (data.entitySlug === 'payment') {
        url = this.$router.resolve({name: 'paymentDetails', params: {paymentId: value}})
      } else if (data.entitySlug === 'invoice') {
        url = this.$router.resolve({name: 'invoiceDetails', params: {invoiceId: value}})
      } else if (data.entitySlug === 'job') {
        url = this.$router.resolve({name: 'jobDetail', params: {jobId: value}})
      } else if (data.entitySlug === 'visit') {
        url = this.$router.resolve({name: 'visitDetail', params: {visitId: value}})
      } else if (data.entitySlug === 'quotation') {
        url = this.$router.resolve({name: 'quoteDetail', params: {quoteId: value}})
      } else if (data.entitySlug === 'customer') {
        url = this.$router.resolve({name: 'customerDetails', params: {customerId: value}})
      } else if (data.entitySlug === 'shift') {
        url = this.$router.resolve({name: 'shiftDetail', params: {shiftId: value}})
      } else if (data.entitySlug === 'lead') {
        url = this.$router.resolve({name: 'LeadDetail', params: {leadId: value}})
      } else if (data.entitySlug === 'employee') {
        url = this.$router.resolve({name: 'SettingEmployeesDetail', params: {empId: value}, query: {showDelele: false}})
      } else if (data.entitySlug === 'item') {
        url = this.$router.resolve({name: 'Items'})
      } else if (data.entitySlug === 'payment_method') {
        url = this.$router.resolve({name: 'SettingPaymentMethod'})
      } else if (data.entitySlug === 'tax') {
        url = this.$router.resolve({name: 'SettingTax'})
      } else if (data.entitySlug === 'expense') {
        url = this.$router.resolve({name: 'ExpenseDetail', params: {expId: value}})
      } else if (data.entitySlug === 'request') {
        url = this.$router.resolve({name: 'requestDetail', params: {requestId: value}})
      }
      if (data.entitySlug !== 'task' && data.entitySlug !== '') {
        window.open(url.href, '_blank')
      }
    },
  discard (data) {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('fullImageHandler', data)
  },
},
beforeDestroy () {
  this.$root.$off("confirmBoxHandler");
}
}
</script>
<style scoped>
.imageFullScreen {
width: auto;
max-height: 100%;
justify-content: center;
align-items: center;
}
.image-show-container {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
</style>